import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_20.mp3';
// import Background from '../images/conseil-2.jpg';
// import Background from '../images/conseil.jpg';
import Background from '../images/conseil-new.webp';
import Borne2 from '../images/Bornes-2.png';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Part2_Conseil = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO pathname="/fr/part-2-conseil" />

    <ViewArticle>
      <ViewModuleName>
        <ViewChoice>
          <ViewImgTop src={Borne2} />
          Gérer mon argent en bon père de famille
        </ViewChoice>
      </ViewModuleName>
    </ViewArticle>
    <Player sound={Sound} />

    <Link to="/fr/part-2-citation">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <Link to="/fr/part-2-astuces">
      <ViewButtonNext color="warning">
        <i className="fa fa-chevron-right" />
      </ViewButtonNext>
    </Link>
    <ShareButtons />
    <Breadcrumbs active={3} />
  </Layout>
);

export default Part2_Conseil;
